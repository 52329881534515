import * as React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import Seo from '../components/functional/seo'

const Styled404 = styled.section`
  text-align: center;
  padding: 4rem 1rem;
`

const NotFoundPage = () => (
  <Layout>
    <Styled404>
      <Seo title="404: Not found" />
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Styled404>
  </Layout>
)

export default NotFoundPage
